<template>
  <b-modal
        id="add-new-item"
        no-close-on-esc
        no-close-on-backdrop
        hide-header
        hide-footer
        centered
        v-model="showModal"
        :size="this.sizeModal"
  >
        <div class="d-flex flex-column my-2">
            <h2>Adicionar colaborador</h2>
            <b-alert class="p-1" variant="danger" :show="item.errorApi.error.length">
                {{item.errorApi.error}}
            </b-alert>
            <validation-observer ref="itemRules">
                <b-form>
                    <b-row>
                      <b-col lg="6" md="12" sm="12" v-if="this.exibirEmpresaSelect">
                          <validation-provider
                              #default="{ errors }"
                              name="empresa"
                              rules="required"
                          >
                            <CompanySelect
                                v-model="companySelectValue"
                                :empresaSelect="empresaSelect"
                                :loading="false"
                                label-class="'novo-colaborador'"
                                label="Empresa*"
                                :disable-all="false"
                            />
                            <small v-if="errors[0]" class="text-danger">
                              Este campo é de preenchimento obrigatório.
                            </small>
                            <small v-if="item.errorApi.empresa" class="text-danger">
                              {{ item.errorApi.empresa }}
                            </small>
                          </validation-provider>
                      </b-col>
                        <b-col lg="6" md="12" sm="12">
                           <b-form-group 
                            label="CPF*"
                            label-for="cpf-input">
                                <validation-provider
                                #default="{ errors }"
                                name="cpf"
                                rules="required"
                                >
                                    <b-form-input
                                        id="cpf-input"
                                        v-model="item.cpf"
                                        placeholder="000.000.000-00"
                                        v-mask="'###.###.###-##'"
                                        @input="searchPessoa"
                                        maxLength="14"
                                    />
                                    <small v-if="errors[0]" class="text-danger">
                                        Este campo é de preenchimento obrigatório.
                                    </small>
                                    <small v-if="item.errorApi.cpf" class="text-danger">
                                    {{ item.errorApi.cpf }}
                                    </small>
                                </validation-provider>
                            </b-form-group>
                       </b-col>

                       <b-col lg="12" md="12" sm="12">
                            <b-form-group 
                            label="Nome*"
                            label-for="nome-input">
                            <validation-provider
                                #default="{ errors }"
                                name="nome"
                                rules="required"
                                >
                                    <b-form-input
                                        id="nome-input"
                                        v-model="item.nome"
                                        placeholder="Nome completo"
                                        :disabled="disabled.nome"
                                    />
                                <small v-if="errors[0]" class="text-danger">
                                    Este campo é de preenchimento obrigatório.
                                </small>
                                <small v-if="item.errorApi.nome" class="text-danger">
                                    {{ item.errorApi.nome }}
                                </small>
                            </validation-provider>
                            </b-form-group>
                       </b-col>

                        <b-col lg="6" md="12" sm="12">
                            <b-form-group 
                                label="Data de nascimento*"
                                label-for="nascimento-input"
                            >
                            <validation-provider
                                #default="{ errors }"
                                name="data_nascimento"
                                rules="required"
                                >
                                <b-input-group>
                                    <b-form-input
                                        class="form-control"
                                        id="nascimento-input"
                                        v-mask="'##/##/####'"
                                        v-model="input.data_nascimento"
                                        placeholder="00/00/0000"
                                        autocomplete="off"
                                        @input="setInput"
                                        :disabled="disabled.data_nascimento"
                                    />
                                    <b-input-group-append>
                                        <b-form-datepicker
                                            class="form-control d-flex align-items-center"
                                            locale="pt-BR"
                                            v-model="item.data_nascimento"
                                            button-only
                                            right
                                            :hide-header="true"
                                            label-help="Selecione a data de nascimento"
                                            button-variant="custom-blue"
                                            clearable
                                            @input="setPicker"
                                        />
                                    </b-input-group-append>
                                </b-input-group>
                                    <small v-if="errors[0]" class="text-danger">
                                        Este campo é de preenchimento obrigatório.
                                    </small>
                                    <small v-if="item.errorApi.data_nascimento" class="text-danger">
                                    {{ item.errorApi.data_nascimento }}
                                    </small>
                            </validation-provider>
                            </b-form-group>
                       </b-col>
                       <b-col lg="6" md="12" sm="12">
                            <b-form-group 
                            label="Sexo*"
                            label-for="genero-input">
                            <validation-provider
                                #default="{ errors }"
                                name="genero"
                                rules="required"
                                >
                                <v-select 
                                id="genero-input"
                                v-model="item.genero"
                                :options="['Masculino', 'Feminino']"
                                placeholder="Selecione uma opção"
                                :clearable="true"
                                >
                                <span slot="no-options">Nenhuma opção selecionável.</span>
                                </v-select>
                                    <small v-if="errors[0]" class="text-danger">
                                        Este campo é de preenchimento obrigatório.
                                    </small>
                                    <small v-if="item.errorApi.genero" class="text-danger">
                                    {{ item.errorApi.genero }}
                                    </small>
                            </validation-provider>
                            </b-form-group>
                       </b-col>
                       <b-col lg="6" md="12" sm="12">
                            <b-form-group 
                            label="Categoria*"
                            label-for="categoria-input">
                            <validation-provider
                                #default="{ errors }"
                                name="categoria"
                                rules="required"
                                >
                                <v-select 
                                id="categoria-input"
                                v-model="item.categoria"
                                :options="categoriaSelect"
                                label="descricao"
                                placeholder="Selecione uma opção"
                                :clearable="true"
                                >
                                <span slot="no-options">Nenhuma opção selecionável.</span>
                                </v-select>
                                    <small v-if="errors[0]" class="text-danger">
                                        Este campo é de preenchimento obrigatório.
                                    </small>
                                    <small v-if="item.errorApi.categoria" class="text-danger">
                                        {{ item.errorApi.categoria }}
                                    </small>
                            </validation-provider>
                            </b-form-group>
                       </b-col>
                      </b-row>
                </b-form>
            </validation-observer>
        </div>
        <section class="d-flex justify-content-center mb-1">
                <b-button id="save-entrada" 
                    class="mr-2 mb-2-sm" 
                    variant="custom-blue"
                    @click.prevent="confirmAction"
                    :disabled="saving"
                >
                    <feather-icon icon="CheckIcon" class="mr-50"/>
                    <span class="align-middle">Adicionar</span>
                    <span v-if="saving" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
                </b-button>
                <b-button id="cancel-entrada" 
                    :disabled="saving"
                    variant="outline-danger"
                    @click.prevent="cancelAction"
                >
                    <feather-icon icon="XIcon" class="mr-50"/>
                    <span class="align-middle">Descartar</span>
                </b-button>
            </section>
    </b-modal>
</template>

<script>
import { BModal, BForm, BCol, BRow, BFormGroup, BFormInput, 
BButton, BTable, VBTooltip, BSpinner, BCard,
BFormDatepicker, BInputGroup, BInputGroupAppend, BAlert } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VueCleave from 'vue-cleave-component'
import handleErrorsService from '@/views/custom-pages/colaborador/Services/HandleErrors';
import { required } from '@validations'
import moment from 'moment';
import vSelect from 'vue-select';
import CompanySelect from "@/views/components/custom/empresa/CompanySelect.vue";

export default {
  components: {
    CompanySelect,
        BModal, BForm, BCol, BRow, BFormGroup, BFormInput, 
BButton, BTable, VBTooltip, BSpinner, BCard, vSelect,
BFormDatepicker, BInputGroup, BInputGroupAppend,
ValidationProvider, ValidationObserver, VueCleave,BAlert, handleErrorsService
    },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    categoriaSelect: {
        type: Array,
        default: []
    },
    idEmpresa: {
        required: false
    },
    empresaSelect: {
      type: Array,
      default: () => []
    },
    exibirEmpresaSelect: {
      type: Boolean,
      default: false
    },
    sizeModal: {
      type: String,
      default: 'md'
    },

  },
  data() {
    return {
        required,
        item: {
            cpf: null,
            nome: null,
            data_nascimento: null,
            genero: '',
            categoria: '',
            empresa: '',
            errorApi: {
                cpf:  '',
                nome: '',
                genero: '',
                data_nascimento: '',
                categoria: '',
                error: ''
            }
        },
        input: {
            data_nascimento:''
        },
        disabled: {
            nome: false,
            data_nascimento: false,
            genero: false
        },
        saving: false,
        companySelectValue: {
          empresa: null
        },
    }
  },
  methods: {
    confirmAction() {
        this.$refs.itemRules.validate().then(success => {
            if (success) {
                const parameters = this.prepareDataToSave();
                this.saving = true;

                this.$http.post(this.$api.colaborador(), parameters)
                .then(() => {
                    this.saving = false;
                    this.$emit('confirmed');
                    this.emptyForm();
                    this.$refs.itemRules.reset();
                    this.item.errorApi.error = '';
                }).catch(({ response: { data } }) => {
                    handleErrorsService.handleErrosColaborador(data, this.item.errorApi);

                    if (typeof data.errors === 'string') {
                        this.item.errorApi.error = data.errors;
                    }

                    this.saving = false;
                })
            }
        })
    },
    prepareDataToSave(){
        return {
            'cpf': this.formatCpf(this.item.cpf),
            'nome': this.item.nome,
            'genero': this.item.genero,
            'data_nascimento': this.item.data_nascimento,
            'id_empresa': this.exibirEmpresaSelect ? this.companySelectValue.empresa.id_empresa : this.$props.idEmpresa,
            'ano': moment().format('YYYY'),
            'id_categoria': this.item.categoria ? this.item.categoria.id_categoria : '',
        };
    },
    cancelAction(modalEvent) {
        this.emptyForm();
        modalEvent.preventDefault();
        this.$emit('canceled', modalEvent);
        this.$refs.itemRules.reset();
    },
    setPicker() {
        this.input.data_nascimento = moment(this.item.data_nascimento).format('DD/MM/YYYY');
    },
    setInput() {
        this.item.data_nascimento = moment(this.input.data_nascimento, 'DD/MM/YYYY').format('YYYY-MM-DD');
    },
    emptyForm() {
        this.item.cpf = '';
        this.item.nome = '';
        this.input.data_nascimento = ''
        this.item.genero = '';
        this.item.categoria = '';
        this.disabled.nome = false;
        this.disabled.data_nascimento = false;
        this.item.errorApi.cpf = '';
        this.item.errorApi.nome = '';
        this.item.errorApi.data_nascimento = '';
        this.item.errorApi.categoria = '';
        this.item.errorApi.error = '';
        this.item.empresa = '';
        this.setInput();
    },
    searchPessoa(){
        if(this.item.cpf.length < 14){
            this.disabled.nome = false;
            this.disabled.data_nascimento = false;
            return;
        }


        this.$http.get(this.$api.getPessoaFisicaPorCpf(this.formatCpf(this.item.cpf)))
        .then(({ data }) => {
            const pessoa = data;
            if(pessoa.nome){
                this.item.nome = pessoa.nome;
                this.item.data_nascimento = pessoa.data_nascimento;
                this.item.genero = pessoa.genero;

                this.disabled.nome = true;
                this.disabled.data_nascimento = true;
                this.setPicker();
            }else{
                this.cleanSearch();
            }
        }).catch(({ response: { data } }) => {
            handleErrorsService.handleErrosColaborador(data, this.item.errorApi);
            this.cleanSearch();
        });
    },
    cleanSearch(){
        this.item.nome = '';
        this.input.data_nascimento = ''
        this.item.genero = '';
        this.disabled.nome = false;
        this.disabled.data_nascimento = false;
        this.setInput();
    },
    formatCpf(cpf){
        return cpf.replace(/\D/g, "");
    },
  },
}
</script>

<style scoped>
    .bloco{
      height: 200px;
    }
    .list-enter-active,
    .list-leave-active {
      transition: all 0.5s ease;
    }
    .list-enter-from,
    .list-leave-to {
      opacity: 0;
      transform: translateX(30px);
    }

    .panel-enter-active,
    .panel-leave-active {
      transition: all 0.5s ease;
    }
    .panel-enter-from,
    .panel-leave-to {
      opacity: 0;
      height: 0;
      transform: translateX(30px);
    }

</style>

<style>
#add-new-item #radio-group-empresa label.custom-control-label {
  font-size: 12px !important;
}
#add-new-item div#radio-group-empresa {
  font-size: 12px;
}
</style>
