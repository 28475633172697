<template>
  <b-modal
        id="add-new-item"
        no-close-on-esc
        no-close-on-backdrop
        hide-header
        hide-footer
        centered
        :size="this.sizeModal"
        v-model="showModal"
    >
        <div class="d-flex flex-column my-2">
            <h2>Dados da Vacinação</h2>
            <span v-if="colaborador && colaborador.length > 1" class="pb-1 text-danger">{{colaborador.length}} pessoas selecionadas</span>
            <b-alert class="p-1" variant="danger" :show="item.errorApi.error != '' ">
                {{item.errorApi.error}}
            </b-alert>
            <validation-observer ref="itemRules">
                <b-form>
                    <b-row>
                        <b-col lg="6" md="12" sm="12">
                           <b-form-group 
                            label="Lote*"
                            label-for="lote-input">
                                <validation-provider
                                #default="{ errors }"
                                name="lote"
                                rules="required"
                                >
                                    <v-select 
                                    id="lote-input"
                                    v-model="item.lote"
                                    :options="loteSelect"
                                    label="numero_lote"
                                    placeholder="Lote da Vacinação"
                                    :clearable="true"
                                    >
                                    <span slot="no-options">Nenhuma opção selecionável.</span>
                                    </v-select>
                                    <small v-if="errors[0]" class="text-danger">
                                        Este campo é de preenchimento obrigatório.
                                    </small>
                                </validation-provider>
                            </b-form-group>
                       </b-col>   
                       <b-col lg="6" md="12" sm="12">
                            <b-form-group 
                            label="Vacinador*"
                            label-for="vacinador-input">
                            <validation-provider
                                #default="{ errors }"
                                name="vacinador"
                                rules="required"
                                >
                                    <v-select 
                                    id="vacinador-input"
                                    v-model="item.vacinador"
                                    :options="vacinadorSelect"
                                    label="nome"
                                    placeholder="Aplicador da vacina"
                                    :clearable="true"
                                    >
                                    <span slot="no-options">Nenhuma opção selecionável.</span>
                                    </v-select>
                                <small v-if="errors[0]" class="text-danger">
                                        Este campo é de preenchimento obrigatório.
                                    </small>
                            </validation-provider>
                            </b-form-group>
                       </b-col>
                        <b-col lg="6" md="12" sm="12">
                            <b-form-group 
                                label="Data da Aplicação*"
                                label-for="aplicacao-input"
                            >
                            <validation-provider
                                #default="{ errors }"
                                name="data_aplicacao"
                                rules="required"
                                >
                                <b-input-group>
                                    <b-form-input
                                        class="form-control"
                                        :state="errorOnDate ? false:null"
                                        id="nascimento-input"
                                        v-mask="'##/##/####'"
                                        v-model="input.data_aplicacao"
                                        placeholder="00/00/0000"
                                        autocomplete="off"
                                        @input="setInput"
                                    />
                                    <b-input-group-append>
                                        <b-form-datepicker
                                            class="form-control d-flex align-items-center"
                                            locale="pt-BR"
                                            :max="new Date()"
                                            v-model="item.data_aplicacao"
                                            button-only
                                            right
                                            :hide-header="true"
                                            label-help="Selecione a data da aplicaçãos"
                                            button-variant="custom-blue"
                                            clearable
                                            @input="setPicker"
                                        />
                                    </b-input-group-append>
                                </b-input-group>
                                    <small v-if="errors[0]" class="text-danger">
                                        Este campo é de preenchimento obrigatório.
                                    </small>
                                    <small v-if="errorOnDate" class="text-danger">
                                        A data da aplicação não pode ser uma data futura. 
                                    </small>
                            </validation-provider>
                            </b-form-group>
                       </b-col>
                       <b-col v-if="exibirEnderecoSelect" lg="6" md="12" sm="12">
                          <b-form-group
                              label="Endereço Empresa*"
                              label-for="endereco-input">
                            <validation-provider
                                #default="{ errors }"
                                name="endereco"
                                rules="required"
                            >
                              <v-select
                                  id="endereco-input"
                                  v-model="item.endereco"
                                  variant="custom"
                                  :options="enderecoSelect"
                                  label="id_empresa_endereco"
                                  placeholder="Endereço empresa"
                                  :clearable="true"
                              >
                                <template #option="data" >
                                  <span>
                                      <b>{{ data.descricao }}</b><br/>
                                      {{ formatEndereco(data) }}
                                  </span>
                                                </template>
                                                <template #selected-option="data">
                                  <span>
                                      <b>{{ data.descricao }}</b><br/>
                                      {{ formatEndereco(data) }}
                                  </span>
                                </template>
                                <span slot="no-options">Nenhuma opção selecionável.</span>
                              </v-select>
                              <small v-if="errors[0]" class="text-danger">
                                Este campo é de preenchimento obrigatório.
                              </small>
                            </validation-provider>
                          </b-form-group>
                       </b-col>
                      </b-row>
                </b-form>
            </validation-observer>
        </div>
        <section class="d-flex justify-content-center mb-1">
                <b-button id="save-entrada"
                    class="mr-2 mb-2-sm" 
                    variant="custom-blue"
                    @click.prevent="confirmAction"
                    :disabled="saving || errorOnDate"
                >
                    <feather-icon icon="CheckIcon" class="mr-50"/>
                    <span class="align-middle">Vacinar</span>
                    <span v-if="saving" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
                </b-button>
                <b-button id="cancel-entrada" 
                    :disabled="saving"
                    variant="outline-danger"
                    @click.prevent="cancelAction"
                >
                    <feather-icon icon="XIcon" class="mr-50"/>
                    <span class="align-middle">Descartar</span>
                </b-button>
            </section>
    </b-modal>
</template>

<script>
import { BModal, BForm, BCol, BRow, BFormGroup, BFormInput, 
BButton, BTable, VBTooltip, BSpinner, BCard,
BFormDatepicker, BInputGroup, BInputGroupAppend, BAlert } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VueCleave from 'vue-cleave-component'
import { required } from '@validations'
import moment from 'moment';
import vSelect from 'vue-select';
import handleErrorsService from '@/views/custom-pages/Vacinacao/Services/HandleErrors';


export default {
  components: {
        BModal, BForm, BCol, BRow, BFormGroup, BFormInput, 
BButton, BTable, VBTooltip, BSpinner, BCard, vSelect,
BFormDatepicker, BInputGroup, BInputGroupAppend,
ValidationProvider, ValidationObserver, VueCleave,BAlert
    },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    loteSelect: {
        type: Array,
        default: []
    },
    vacinadorSelect: {
        type: Array,
        default: []
    },
    colaborador: {
        default: null
    },
    empresa: {
        default: null
    },
    tipoCampanha: {
        default: null
    },
    idCampanha: {
        default: null
    },
    unidade: {
        default: null
    },
    idEndereco: {
        default: null
    },
    idDepartamento: {
        default: null
    },
    enderecoSelect: {
      type: Array,
      default: () => []
    },
    exibirEnderecoSelect: {
      type: Boolean,
      default: false
    },
    sizeModal: {
      type: String,
      default: 'md'
    }
  },
  data() {
    return {
        required,
        item: {
            lote: null,
            vacinador: null,
            endereco: null,
            data_aplicacao: new Date(),
            errorApi: {
                error: ''
            }
        },
        input: {
            data_aplicacao: ''
        },
        errorOnDate: false,
        saving: false
    }
  },

  mounted() {
    this.setPicker();
  },

  watch: {
    enderecoSelect() {
      if (this.exibirEnderecoSelect && this.enderecoSelect.length === 1) {
        this.item.endereco = this.enderecoSelect[0];
      }
    },
    loteSelect() {
      if (this.loteSelect.length === 0) {
        this.item.errorApi.error = 'Nenhum lote disponível para esta campanha.';
      } else {
        this.item.errorApi.error = '';
      }
    }
  },

  methods: {
    confirmAction() {
        this.$refs.itemRules.validate().then(success => {
            if (success) {
                const parameters = this.prepareDataToSave();
                this.saving = true;

                this.$http.post(this.$api.vacinacao(), parameters)
                .then(() => {
                    this.saving = false;
                    this.$emit('confirmed');
                    this.emptyForm();
                    this.$refs.itemRules.reset();
                })
                .catch(({ response: { data } }) => {
                    handleErrorsService.handleErrosVacinacao(data, this.item.errorApi);
                    this.saving = false;
                })
            }
        })
    },
    prepareDataToSave() {
        let pessoas = [];       
        const isMassive = this.colaborador.length > 1 ? true : false
        this.colaborador.map((item) => {
            pessoas.push({
                id_pessoa_fisica: item.id_pessoa_fisica,
                id_categoria: item.id_categoria,
            })
        })

        const pessoasSemDuplicatas = pessoas.filter((item, index, self) => self.findIndex(t => t.id_pessoa_fisica === item.id_pessoa_fisica) === index);       

        const params = {
            id_empresa: this.empresa.id_empresa,
            id_campanha: this.idCampanha,
            id_unidade: this.unidade.id_unidade,
            id_lote: this.item.lote.id_lote,
            id_usuario_aplicador: this.item.vacinador.id_usuario,
            data_aplicacao: this.item.data_aplicacao,
            dose: 1,
            massiva: isMassive,
            pessoas: pessoasSemDuplicatas,
            id_empresa_endereco: this.exibirEnderecoSelect ? this.item.endereco.id_empresa_endereco : this.idEndereco,
            tipo: this.tipoCampanha

        };

        if (this.idDepartamento) {
          params['id_departamento'] = this.idDepartamento;
        }

        return params;
    },
    cancelAction(modalEvent) {
        modalEvent.preventDefault();
        this.$emit('canceled', modalEvent);
        this.$refs.itemRules.reset();
        this.emptyForm();
    },
    setPicker() {
        this.errorOnDate = false;

        this.input.data_aplicacao = moment(this.item.data_aplicacao).format('DD/MM/YYYY');
    },
    setInput() {
        this.errorOnDate = false;

        if(new Date(this.item.data_aplicacao) > new Date()) {
            this.errorOnDate = true;
        }

        this.item.data_aplicacao = moment(this.input.data_aplicacao, 'DD/MM/YYYY').format('YYYY-MM-DD');
    },
    emptyForm() {
        this.item.vacinador = '';
        this.item.lote = '';
        this.item.data_aplicacao = new Date();
        this.item.endereco = '';
        this.setPicker();
        this.item.errorApi.error = ''
    },
    formatEndereco(endereco) {
      let descricao = '';

      if(endereco.logradouro) {descricao += `${endereco.logradouro}, ` };
      if(endereco.numero) { descricao += `${endereco.numero}, ` };
      if(endereco.bairro) { descricao += `${endereco.bairro}, ` };
      if(endereco.cidadeNome) { descricao += `${endereco.cidadeNome}, ` };
      if(endereco.estadoUF) { descricao += `${endereco.estadoUF}` };

      return descricao;
    },
  },
}
</script>

<style scoped>
    .bloco{
      height: 200px;
    }
    .list-enter-active,
    .list-leave-active {
      transition: all 0.5s ease;
    }
    .list-enter-from,
    .list-leave-to {
      opacity: 0;
      transform: translateX(30px);
    }

    .panel-enter-active,
    .panel-leave-active {
      transition: all 0.5s ease;
    }
    .panel-enter-from,
    .panel-leave-to {
      opacity: 0;
      height: 0;
      transform: translateX(30px);
    }
</style>
