var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"add-new-item","no-close-on-esc":"","no-close-on-backdrop":"","hide-header":"","hide-footer":"","centered":"","size":this.sizeModal},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('div',{staticClass:"d-flex flex-column my-2"},[_c('h2',[_vm._v("Dados da Vacinação")]),(_vm.colaborador && _vm.colaborador.length > 1)?_c('span',{staticClass:"pb-1 text-danger"},[_vm._v(_vm._s(_vm.colaborador.length)+" pessoas selecionadas")]):_vm._e(),_c('b-alert',{staticClass:"p-1",attrs:{"variant":"danger","show":_vm.item.errorApi.error != ''}},[_vm._v(" "+_vm._s(_vm.item.errorApi.error)+" ")]),_c('validation-observer',{ref:"itemRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"lg":"6","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Lote*","label-for":"lote-input"}},[_c('validation-provider',{attrs:{"name":"lote","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"lote-input","options":_vm.loteSelect,"label":"numero_lote","placeholder":"Lote da Vacinação","clearable":true},model:{value:(_vm.item.lote),callback:function ($$v) {_vm.$set(_vm.item, "lote", $$v)},expression:"item.lote"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhuma opção selecionável.")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Vacinador*","label-for":"vacinador-input"}},[_c('validation-provider',{attrs:{"name":"vacinador","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vacinador-input","options":_vm.vacinadorSelect,"label":"nome","placeholder":"Aplicador da vacina","clearable":true},model:{value:(_vm.item.vacinador),callback:function ($$v) {_vm.$set(_vm.item, "vacinador", $$v)},expression:"item.vacinador"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhuma opção selecionável.")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Data da Aplicação*","label-for":"aplicacao-input"}},[_c('validation-provider',{attrs:{"name":"data_aplicacao","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"form-control",attrs:{"state":_vm.errorOnDate ? false:null,"id":"nascimento-input","placeholder":"00/00/0000","autocomplete":"off"},on:{"input":_vm.setInput},model:{value:(_vm.input.data_aplicacao),callback:function ($$v) {_vm.$set(_vm.input, "data_aplicacao", $$v)},expression:"input.data_aplicacao"}}),_c('b-input-group-append',[_c('b-form-datepicker',{staticClass:"form-control d-flex align-items-center",attrs:{"locale":"pt-BR","max":new Date(),"button-only":"","right":"","hide-header":true,"label-help":"Selecione a data da aplicaçãos","button-variant":"custom-blue","clearable":""},on:{"input":_vm.setPicker},model:{value:(_vm.item.data_aplicacao),callback:function ($$v) {_vm.$set(_vm.item, "data_aplicacao", $$v)},expression:"item.data_aplicacao"}})],1)],1),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.errorOnDate)?_c('small',{staticClass:"text-danger"},[_vm._v(" A data da aplicação não pode ser uma data futura. ")]):_vm._e()]}}])})],1)],1),(_vm.exibirEnderecoSelect)?_c('b-col',{attrs:{"lg":"6","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Endereço Empresa*","label-for":"endereco-input"}},[_c('validation-provider',{attrs:{"name":"endereco","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"endereco-input","variant":"custom","options":_vm.enderecoSelect,"label":"id_empresa_endereco","placeholder":"Endereço empresa","clearable":true},scopedSlots:_vm._u([{key:"option",fn:function(data){return [_c('span',[_c('b',[_vm._v(_vm._s(data.descricao))]),_c('br'),_vm._v(" "+_vm._s(_vm.formatEndereco(data))+" ")])]}},{key:"selected-option",fn:function(data){return [_c('span',[_c('b',[_vm._v(_vm._s(data.descricao))]),_c('br'),_vm._v(" "+_vm._s(_vm.formatEndereco(data))+" ")])]}}],null,true),model:{value:(_vm.item.endereco),callback:function ($$v) {_vm.$set(_vm.item, "endereco", $$v)},expression:"item.endereco"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhuma opção selecionável.")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e()]}}],null,false,4274949624)})],1)],1):_vm._e()],1)],1)],1)],1),_c('section',{staticClass:"d-flex justify-content-center mb-1"},[_c('b-button',{staticClass:"mr-2 mb-2-sm",attrs:{"id":"save-entrada","variant":"custom-blue","disabled":_vm.saving || _vm.errorOnDate},on:{"click":function($event){$event.preventDefault();return _vm.confirmAction.apply(null, arguments)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Vacinar")]),(_vm.saving)?_c('span',{staticClass:"spinner-border spinner-border-sm ml-1",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e()],1),_c('b-button',{attrs:{"id":"cancel-entrada","disabled":_vm.saving,"variant":"outline-danger"},on:{"click":function($event){$event.preventDefault();return _vm.cancelAction.apply(null, arguments)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"XIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Descartar")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }