var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"add-new-item","no-close-on-esc":"","no-close-on-backdrop":"","hide-header":"","hide-footer":"","centered":"","size":this.sizeModal},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('div',{staticClass:"d-flex flex-column my-2"},[_c('h2',[_vm._v("Adicionar colaborador")]),_c('b-alert',{staticClass:"p-1",attrs:{"variant":"danger","show":_vm.item.errorApi.error.length}},[_vm._v(" "+_vm._s(_vm.item.errorApi.error)+" ")]),_c('validation-observer',{ref:"itemRules"},[_c('b-form',[_c('b-row',[(this.exibirEmpresaSelect)?_c('b-col',{attrs:{"lg":"6","md":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":"empresa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CompanySelect',{attrs:{"empresaSelect":_vm.empresaSelect,"loading":false,"label-class":"'novo-colaborador'","label":"Empresa*","disable-all":false},model:{value:(_vm.companySelectValue),callback:function ($$v) {_vm.companySelectValue=$$v},expression:"companySelectValue"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.item.errorApi.empresa)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.item.errorApi.empresa)+" ")]):_vm._e()]}}],null,false,2043967361)})],1):_vm._e(),_c('b-col',{attrs:{"lg":"6","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"CPF*","label-for":"cpf-input"}},[_c('validation-provider',{attrs:{"name":"cpf","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],attrs:{"id":"cpf-input","placeholder":"000.000.000-00","maxLength":"14"},on:{"input":_vm.searchPessoa},model:{value:(_vm.item.cpf),callback:function ($$v) {_vm.$set(_vm.item, "cpf", $$v)},expression:"item.cpf"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.item.errorApi.cpf)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.item.errorApi.cpf)+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Nome*","label-for":"nome-input"}},[_c('validation-provider',{attrs:{"name":"nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nome-input","placeholder":"Nome completo","disabled":_vm.disabled.nome},model:{value:(_vm.item.nome),callback:function ($$v) {_vm.$set(_vm.item, "nome", $$v)},expression:"item.nome"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.item.errorApi.nome)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.item.errorApi.nome)+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Data de nascimento*","label-for":"nascimento-input"}},[_c('validation-provider',{attrs:{"name":"data_nascimento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"form-control",attrs:{"id":"nascimento-input","placeholder":"00/00/0000","autocomplete":"off","disabled":_vm.disabled.data_nascimento},on:{"input":_vm.setInput},model:{value:(_vm.input.data_nascimento),callback:function ($$v) {_vm.$set(_vm.input, "data_nascimento", $$v)},expression:"input.data_nascimento"}}),_c('b-input-group-append',[_c('b-form-datepicker',{staticClass:"form-control d-flex align-items-center",attrs:{"locale":"pt-BR","button-only":"","right":"","hide-header":true,"label-help":"Selecione a data de nascimento","button-variant":"custom-blue","clearable":""},on:{"input":_vm.setPicker},model:{value:(_vm.item.data_nascimento),callback:function ($$v) {_vm.$set(_vm.item, "data_nascimento", $$v)},expression:"item.data_nascimento"}})],1)],1),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.item.errorApi.data_nascimento)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.item.errorApi.data_nascimento)+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Sexo*","label-for":"genero-input"}},[_c('validation-provider',{attrs:{"name":"genero","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"genero-input","options":['Masculino', 'Feminino'],"placeholder":"Selecione uma opção","clearable":true},model:{value:(_vm.item.genero),callback:function ($$v) {_vm.$set(_vm.item, "genero", $$v)},expression:"item.genero"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhuma opção selecionável.")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.item.errorApi.genero)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.item.errorApi.genero)+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Categoria*","label-for":"categoria-input"}},[_c('validation-provider',{attrs:{"name":"categoria","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"categoria-input","options":_vm.categoriaSelect,"label":"descricao","placeholder":"Selecione uma opção","clearable":true},model:{value:(_vm.item.categoria),callback:function ($$v) {_vm.$set(_vm.item, "categoria", $$v)},expression:"item.categoria"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhuma opção selecionável.")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.item.errorApi.categoria)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.item.errorApi.categoria)+" ")]):_vm._e()]}}])})],1)],1)],1)],1)],1)],1),_c('section',{staticClass:"d-flex justify-content-center mb-1"},[_c('b-button',{staticClass:"mr-2 mb-2-sm",attrs:{"id":"save-entrada","variant":"custom-blue","disabled":_vm.saving},on:{"click":function($event){$event.preventDefault();return _vm.confirmAction.apply(null, arguments)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Adicionar")]),(_vm.saving)?_c('span',{staticClass:"spinner-border spinner-border-sm ml-1",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e()],1),_c('b-button',{attrs:{"id":"cancel-entrada","disabled":_vm.saving,"variant":"outline-danger"},on:{"click":function($event){$event.preventDefault();return _vm.cancelAction.apply(null, arguments)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"XIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Descartar")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }